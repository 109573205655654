import {FloorChoice} from "../../../../../real_estate/types/FloorChoice";
import {OfferListSubType} from "../../../types/OfferListSubType";

export function getFloorChoiceByOfferListSubType(offerListSubType: string) {
    switch (offerListSubType) {
        case OfferListSubType.FLOOR_GROUND:
            return FloorChoice.GROUND;
        case OfferListSubType.FLOOR_GROUND_WITH_GARDEN:
            return FloorChoice.GROUND_WITH_GARDEN;
        case OfferListSubType.LAST_FLOOR:
            return FloorChoice.LAST_LEVEL;
        default:
            throw new Error(`Cannot transform offer's sub-type (${offerListSubType}) into FloorChoice`);
    }
}
