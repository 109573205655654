import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IVendorDetail} from "../types/IVendorDetail";

export const fetchVendorDetailById = (meta: IRPRequestMeta, vendorId: number): Promise<IVendorDetail> => {
    const requestUrl = apiV2Link.vendor.detail(Scenario.VENDOR_DETAIL, {vendorId});

    return getRequest(meta, requestUrl);
};
