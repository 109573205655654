import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual, isPlainObject, omit} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {isBrowser} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {gtmEventOfferListSearch} from "../../../tracking/google_tag_manager/gtm_event_ui";
import {OfferDisplayType} from "../../helpers/OfferDisplayType";
import {fetchPropertiesCount} from "../../list/actions/fetch_properties_count";
import {DEFAULT_DISTANCE} from "../../list/constants/offer_list";
import {getFetchOfferListQuery} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {IOfferListResponse} from "../../list/types/IOfferListResponse";
import {OfferListSubType} from "../../list/types/OfferListSubType";
import {IOfferListOffer} from "../../types/IOfferListOffer";
import {OfferType} from "../../types/OfferType";

const OFFER_LIST_MAP_PAGE_SIZE = 1000;
export const OFFER_LIST_MAP_DESKTOP = "map/OFFER_LIST_MAP_DESKTOP";
export const fetchDesktopMapOfferListTypes = createRequestActionTypes(OFFER_LIST_MAP_DESKTOP);

export interface IOfferListMapOffer {
    geo_point: {
        coordinates: [number, number];
    };
    stats: {
        ranges_rooms_max: number;
        ranges_rooms_min: number;
        properties_count_for_sale: number;
    } | null;
    id: number;
    name: string;
    region: {
        country: number;
    };
    vendor: {
        name: string;
    };
    type: number;
}

// additional parameters that describe what offer-list should be
const offerListConstrains = {
    limited_presentation: "False",
    display_type: [OfferDisplayType.FOR_SALE],
    for_sale: "True",
    show_on_listing: "True"
};

const offerListMapOfferLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST_MAP);

export const fetchMapOfferListAtRoute =
    (ctx: IFetchContext<IRPRequestMeta, {offerListSubType?: OfferListSubType}>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const {offerListSubType} = ctx.match.params;
        const query = getFetchOfferListQuery(isPlainObject(ctx.prevResult) && ctx.prevResult !== null ? ctx.prevResult : ctx.route.query, offerListSubType);
        const {latestQuery, requestState} = getState().offerListMap.list;
        if (requestState !== RequestState.None && isEqual(latestQuery, query)) {
            return false; // prevent fetching
        }

        if (isBrowser) {
            // gtm - map filters
            !(isEqual(latestQuery.rooms_0, query.rooms_0) && isEqual(latestQuery.rooms_1, query.rooms_1)) && gtmEventOfferListSearch("pokoje");
            !(isEqual(latestQuery.area_0, query.area_0) && isEqual(latestQuery.area_1, query.area_1)) && gtmEventOfferListSearch("powierzchnia");
            !(isEqual(latestQuery.price_0, query.price_0) && isEqual(latestQuery.price_1, query.price_1)) && gtmEventOfferListSearch("cena");
            !isEqual(latestQuery.floor_choices, query.floor_choices) && gtmEventOfferListSearch("piętro");
            !isEqual(latestQuery.construction_end_date, query.construction_end_date) && gtmEventOfferListSearch("termin oddania");
        }

        dispatch({type: fetchDesktopMapOfferListTypes.start, latestQuery: query});
        // "s" query param clashes with scenario parameter when searching Google places
        const safeQuery = omit(query, ["s"]);
        // prepare full-query
        const fullQuery = {
            distance: DEFAULT_DISTANCE,
            ...safeQuery,
            ...(safeQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {}),
            ...offerListConstrains,
            page_size: 0
        };

        // define url and start fetch
        const initialUrl = appendQueryString(offerListMapOfferLink, fullQuery);
        const initialData: IOfferListResponse = await getRequest(ctx.meta, initialUrl);
        const pages = Math.ceil(initialData.count / 1000);
        fullQuery.page_size = OFFER_LIST_MAP_PAGE_SIZE;
        const urls = Array.from({length: pages}, (_, index) => index + 1).map((page) => appendQueryString(offerListMapOfferLink, {...fullQuery, page}));
        const result: {
            offers: IOfferListOffer[];
            count: number;
            page_size: number;
        } = {
            count: 0,
            page_size: OFFER_LIST_MAP_PAGE_SIZE,
            offers: []
        };

        const promises = urls.map((url) => getRequest(ctx.meta, url).then((json: IOfferListResponse) => json));

        try {
            const [_propertiesCount, ...offerListResponses] = await Promise.all([
                dispatch(fetchPropertiesCount(ctx, {...safeQuery, ...offerListConstrains})),
                ...promises
            ]);
            offerListResponses.forEach((response) => {
                result.count = response.count;
                result.offers.push(...response.results);
                result.page_size = response.page_size;
            });

            dispatch({type: fetchDesktopMapOfferListTypes.success, result});

            return {
                count: result.count,
                next: null,
                page: 1,
                page_size: result.page_size,
                previous: null,
                results: result.offers
            } as IOfferListResponse;
        } catch (error) {
            catch400((error) => {
                dispatch({type: fetchDesktopMapOfferListTypes.error, error: error.appError});
            });
        }
    };
