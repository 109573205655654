import {combineReducers} from "redux";

import {reduceReducers, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fetchOfferDetailArticlesTypes, fetchPropertyDetailArticlesTypes} from "../../offer/detail/actions/fetch_offer_detail_articles";
import {IOfferDetailPromotion} from "../../offer/detail/actions/fetch_offer_detail_promotions";
import {fetchOfferDetailRecommendedRegionsTypes} from "../../offer/detail/actions/fetch_offer_detail_recommended_regions";
import {IOfferArticle} from "../../offer/list/actions/fetch_offer_list_articles";
import {IRecommendedRegion} from "../../offer/list/actions/fetch_offer_list_recommended_regions";
import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {fetchLastSeenPropertiesDetailsTypes} from "../actions/fetch_last_seen_properties_details";
import {fetchPropertyDetailTypes} from "../actions/fetch_property_detail";
import {fetchPropertyDetailRelatedOffersTypes} from "../actions/fetch_property_detail_offers_list";
import {fetchDesktopPropertyDetailPromotionsTypes, IPropertyDetailPromotion} from "../actions/fetch_property_detail_promotions";
import {fetchPlannerEstateDetailsTypes, IPlannerEstateDetails} from "../actions/fetch_property_planner_estate_details";
import {fetchRecommendedPropertiesTypes} from "../actions/fetch_recommended_properties";
import {IPropertyDetail} from "../types/IPropertyDetail";
import {IOtherPropertiesInOfferStore, otherPropertiesInOfferStore} from "./other_properties_in_offer_reducer";
import {IRegionStatsStore, regionStatsReducer} from "./region_stats_reducer";

export interface IPropertyDetailStore {
    property: IPropertyDetail | null;
    propertyRequest: RequestState;
    // related articles
    articles: IOfferArticle[];
    articlesRequest: RequestState;
    // last seen properties details (retargeting)
    lastSeenPropertiesDetails: {[key in string]: IPropertyDetail};
    // // Related offers (limited presentation purpose) with near_by_offer and distance filter
    relatedOffers: IOfferListOffer[];
    relatedOffersRequestState: RequestState;
    // recommended regions for sidebar
    recommendedRegions: IRecommendedRegion[];
    recommendedRegionsRequestState: RequestState;
    // promotions
    promotions: IPropertyDetailPromotion[];
    promotionsRequest: RequestState;
    // recommended properties
    recommendedProperties: IRecommendedProperty[];
    // similar properties (slider)
    otherPropertiesInOffer: IOtherPropertiesInOfferStore;
    // properties stats
    stats: IRegionStatsStore;
    plannerEstateDetails: IPlannerEstateDetails | null;
}

export const propertyDetailReducer = combineReducers({
    property: reduceReducers(reduceResponse<IPropertyDetail>(fetchPropertyDetailTypes)),
    propertyRequest: reduceRequestState(fetchPropertyDetailTypes),
    //related articles
    articles: reduceResponse<IOfferArticle[]>(fetchPropertyDetailArticlesTypes, []),
    articlesRequest: reduceRequestState(fetchOfferDetailArticlesTypes),
    // last seen properties details (retargeting)
    lastSeenPropertiesDetails: reduceResponse<{[key in string]: IPropertyDetail}>(fetchLastSeenPropertiesDetailsTypes, {}),
    // Related offers (limited presentation purpose) with near_by_offer and distance filter
    relatedOffers: reduceResponseField<IOfferListOffer[]>(fetchPropertyDetailRelatedOffersTypes, "offers", []),
    relatedOffersRequestState: reduceRequestState(fetchPropertyDetailRelatedOffersTypes),
    // // recommended regions for sidebar
    recommendedRegions: reduceResponseField<IRecommendedRegion[]>(fetchOfferDetailRecommendedRegionsTypes, "regions", []),
    recommendedRegionsRequestState: reduceRequestState(fetchOfferDetailRecommendedRegionsTypes),
    // // promotions
    promotions: reduceResponse<IOfferDetailPromotion[]>(fetchDesktopPropertyDetailPromotionsTypes, []),
    promotionsRequest: reduceRequestState(fetchDesktopPropertyDetailPromotionsTypes),
    // recommended properties
    recommendedProperties: reduceResponse<IRecommendedProperty[]>(fetchRecommendedPropertiesTypes, []),
    // similar properties (slider)
    otherPropertiesInOffer: otherPropertiesInOfferStore,
    // // properties stats
    stats: regionStatsReducer,
    plannerEstateDetails: reduceReducers(reduceResponse<IPlannerEstateDetails>(fetchPlannerEstateDetailsTypes))
});
