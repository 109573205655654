import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IVendorOffer} from "../../offer/types/VendorOffer";
import {fetchVendorDetailTypes} from "../actions/fetch_vendor_detail";
import {fetchVendorDetailCitiesTypes, IVendorDetailCity} from "../actions/fetch_vendor_detail_cities";
import {fetchVendorDetailOffersByRegionTypes, fetchVendorDetailOffersTypes} from "../actions/fetch_vendor_detail_offers";
import {fetchDesktopVendorDetailRankingsTypes, IRanking} from "../actions/fetch_vendor_detail_rankings";
import {fetchVendorDetailArticlesTypes, IVendorDetailArticle} from "../actions/fetch_vendor_detail_related_articles";
import {fetchVendorsCountTypes} from "../actions/fetch_vendors_count";
import {IVendorDetail} from "../types/IVendorDetail";

export interface IVendorDetailStore {
    articles: IVendorDetailArticle[];
    cities: IVendorDetailCity[];
    offers: IVendorOffer[];
    offersCount: number | null;
    offersPropertiesCount: number | null; // additional field that represents `fetchPropertiesCount` result
    offersRequest: RequestState;
    offersInRegion: IVendorOffer[];
    offersInRegionRequest: RequestState;
    rankings: IRanking[];
    vendor: IVendorDetail | null;
    vendorRequest: RequestState;
    count: number;
}

export const vendorDetailReducer = combineReducers({
    articles: reduceResponse<IVendorDetailArticle[]>(fetchVendorDetailArticlesTypes),
    cities: reduceResponse<IVendorDetailCity[]>(fetchVendorDetailCitiesTypes),
    offers: reduceResponseField<IVendorOffer[]>(fetchVendorDetailOffersTypes, "offers", []),
    offersCount: reduceResponseField<number>(fetchVendorDetailOffersTypes, "count", null),
    offersRequest: reduceRequestState(fetchVendorDetailOffersTypes),
    offersInRegion: reduceResponseField<IVendorOffer[]>(fetchVendorDetailOffersByRegionTypes, "offers", []),
    offersInRegionRequest: reduceRequestState(fetchVendorDetailOffersByRegionTypes),
    rankings: reduceResponse(fetchDesktopVendorDetailRankingsTypes),
    vendor: reduceResponse<IVendorDetail>(fetchVendorDetailTypes),
    vendorRequest: reduceRequestState(fetchVendorDetailTypes),
    count: reduceResponse<IVendorDetail>(fetchVendorsCountTypes)
});
